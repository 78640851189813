<template>
  <!-- 发运信息详情 -->
  <div class="app-container">
    <el-row>
      <div>{{ $t('title.BasicInformation') }}</div>
      <el-divider />
    </el-row>
    <el-form label-width="102px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="处置申请单号">{{ DetailsDatas.disposeCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处置类型">{{ getDepositLabel(DetailsDatas.disposeType) }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处置仓库">{{ DetailsDatas.warehouseCode }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处置数量">{{ DetailsDatas.totalQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预估处置金额">{{ DetailsDatas.forecastAmount }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="回收费用">{{ DetailsDatas.recycleAmount }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人">{{ DetailsDatas.logicWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请日期">{{ getReceiveTypeLabel(DetailsDatas.applyDate) }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审批日期">{{ DetailsDatas.approveDate }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="交付方式">{{ DetailsDatas.deliveryWay }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态">{{ DetailsDatas.status == '1' ? '待审批': DetailsDatas.status == '2' ? '审批通过' :'审批驳回' }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注">{{ DetailsDatas.remark }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row>
      <div>{{ $t('title.BasicInformation') }}</div>
      <el-divider />
    </el-row>
    <el-form label-width="102px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="收件人">{{ (recipientInfo.lastName || '') + (recipientInfo.firstName || '') }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电话">{{ recipientInfo.phone }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮箱">{{ getDepositLabel(recipientInfo.email) }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收件地址">{{ (recipientInfo.country || '') + (recipientInfo.state || '') +
              (recipientInfo.county || '') + (recipientInfo.address || '') + (recipientInfo.houseNumber || '') }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮编">{{ getReceiveTypeLabel(recipientInfo.postCode) }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row>
      <div>处置明细</div>
      <el-divider />
    </el-row>
    <el-table ref="multipleTable" class="mb-3" :data="InfoTable" :header-cell-style="{background:'#fafafa'}" max-height="500px">
      <el-table-column
        type="index"
        :label="$t('page.No')"
        align="center"
      />
      <el-table-column
        prop="sku"
        label="SKU"
        align="center"
      />
      <el-table-column
        prop="siteCode"
        label="PlatSKU"
        align="center"
      />
      <el-table-column
        prop="style"
        label="Style"
        align="center"
      />
      <el-table-column
        prop="color"
        label="Color"
        align="center"
      />
      <el-table-column
        prop="size"
        label="Size"
        align="center"
      />
      <el-table-column
        prop="warehouseCode"
        label="处置仓库"
        align="center"
      />
      <el-table-column
        prop="goodQuantity"
        label="良品应处置数量"
        align="center"
      />
      <el-table-column
        prop="badQuantity"
        label="不良品应处置数量"
        align="center"
      />
      <el-table-column
        prop="goodDisposeQuantity"
        label="良品处置数量"
        align="center"
      />
      <el-table-column
        prop="badDisposeQuantity"
        label="不良品处置数量"
        align="center"
      />
    </el-table>
  </div>

</template>

<script>
import { getDisposeDetail } from '@/api/disposal-apply'
import { Mixin } from '@/mixin/mixin.js'
export default {
  mixins: [Mixin],
  data() {
    return {
      DetailsDatas: {},
      recipientInfo: {},
      InfoTable: []
    }
  },

  mounted() {
    this._getDisposeDetail()
  },
  methods: {
    // 获取详情数据
    async _getDisposeDetail() {
      const { disposeCode } = this.$route.query
      const data = { disposeCode }
      const { datas } = await getDisposeDetail(data)
      this.DetailsDatas = datas
      this.recipientInfo = datas.addressRequest || {}
      this.InfoTable = datas.detailList || []
    }

  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
</style>
